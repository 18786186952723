// @import 'swiper/src/swiper';
// @import 'swiper/src/components/navigation/navigation';
// @import 'swiper/src/components/pagination/pagination';
// @import '../../../node_modules/swiper/swiper-bundle.css';

.swiper-button-up,
.swiper-button-down {
  position: absolute;
  left: 0;
  right: 0;
  height: 35px;
  z-index: 1;
  background-color: $alabaster;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  color: $emperor;
}

.swiper-button-up {
  top: -35px;
  &.swiper-button-disabled {
    display: none;
  }
}

.swiper-button-down {
  bottom: 0px;

  &.swiper-button-disabled {
    height: 25px;
    display: block;
    i {
      display: none;
    }
  }
}

.swiper-container-vertical>.swiper-wrapper {
  flex-direction: column;
}
.swiper-pagination-bullets {
  width: 100% !important;
}

.swiper-pagination {
	&-bullet {
		background: #C6C6C6 !important;
		opacity: .5 !important;
    transition: 300ms !important;

		width: 15px !important;
		height: 15px !important;

		&-active {
			width: 30px !important;

			background: #ffffff !important;
			opacity: .9 !important;

			border-radius: 8px !important;
		}
	}

  &.red {
    .swiper-pagination-bullet-active {
		  background: #EB0015 !important;
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-button-white {
  color: #ffffff !important;
}

.float-arrow-left {
  height: 100% !important;
  top: 0 !important;
  margin-top: 0 !important;
  width: 100px !important;
  left: 0 !important;
  background: linear-gradient(-90deg, transparent,#000);
}

.float-arrow-right {
  height: 100% !important;
  top: 0 !important;
  margin-top: 0 !important;
  width: 100px !important;
  right: 0 !important;
  background: linear-gradient(90deg, transparent,#000);
}

.spotlight {
  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    pointer-events: all !important;
  }
}


.swiper-scrollbar {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 50;
  height: 5px;
  width: 100%;

  &--default {
    left: 0 !important;
    width: 100% !important;

    .swiper-scrollbar-drag {
      background: #959393 !important;
    }
  }

  &--primary {
    left: 15px !important;
    width: calc(100% - 15px) !important;

    .swiper-scrollbar-drag {
      background: #959393 !important;
    }
  }
}

.swiper-scrollbar-drag {
  background: #eb0015 !important;
}

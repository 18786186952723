.breadcrumbs-control {
  border-bottom: 2px solid #EAEAEA;
  padding-bottom: 2px;
  margin-bottom: 20px;

  &__label {
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #000000;

    &::after {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #EB0015;
      left: 0;
      top: calc(100% + 2px);
    }

    &--main {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 27px;
      color: #605F5F;
    }
  }

  &__nav-link {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    /* identical to box height, or 175% */

    /* Black/500 (Gray) */
    color: #959393;
  }

  &__nav-button {
    cursor: pointer;
  }

  &--dark {
    border-color: #3c3c3c;

    .breadcrumbs-control__label {
      color: #ffffff;
    }
  }
}

.pagination {
  &__button {
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    border-radius: 4px;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    text-align: center;

    /* Black/700 (Base) */
    color: #3F3B3B;
    background-color: #FFF;

    padding: 5px 7px;
  }
}

.page-link {
  &__prev,
  &__next {
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    color: #EB0015;
  }
}

.preloading {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  img {
    // -webkit-animation: stretch 2000ms infinite ease;
		// animation: stretch 2000ms infinite ease;
    -webkit-transition: .6s;
		-o-transition: .6s;
		transition: .6s;
  }
}

@keyframes stretch {
  0% {
    -webkit-transform: scale(.3) rotate(0deg);
    transform: scale(.3) rotate(0deg);
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
  }
  100% {
    -webkit-transform: scale(1.5) rotate(360deg);
    transform: scale(1.5) rotate(360deg);
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
  }
}

@-webkit-keyframes stretch {
  0% {
    -webkit-transform: scale(.3) rotate(0deg);
    transform: scale(.3) rotate(0deg);
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
  }
  100% {
    -webkit-transform: scale(1.5) rotate(360deg);
    transform: scale(1.5) rotate(360deg);
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
  }
}

.header {
  box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.15);

  &__top {
    background: #161616;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    /* identical to box height, or 175% */

    text-align: center;

    color: #848484;
    height: 32px;
  }


  &__default-link {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    text-align: center;

    color: #ffffff;
    display: flex;
    align-items: center;


    i {
      font-size: 10px;
    }

    @media screen and (max-width: 540px) {
      i{
        display: none;
      }
      font-size: 12px;
    }
  }


  &__red-link {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    text-align: center;

    color: #FF0000;
    display: flex;
    align-items: center;
    white-space: nowrap; /* the text does not fall on the bottom line on narrow screens */

    i {
      font-size: 10px;
    }

    @media screen and (max-width: 540px) {
      i {
        display: none;
      }
      font-size: 12px;
      padding-left:10px;
    }
    @media screen and (min-width: 540px) {
      padding-left:20px;
    }
  }

  &__blue-link {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    text-align: center;

    color: #0552C2;
    display: flex;
    align-items: center;
    white-space: nowrap; /* the text does not fall on the bottom line on narrow screens */

    i {
      font-size: 10px;
    }

    @media screen and (max-width: 540px) {
      i {
        display: none;
      }
      font-size: 12px;
      padding-left:10px;
      color: #4D92FA;
    }
    @media screen and (min-width: 540px) {
      padding-left:20px;
    }
  }

  &__link {
    border: 0;
    background-color: transparent;
    padding: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */

    text-align: center;
    text-transform: uppercase;

    color: #CCCCCC;
    cursor: pointer;

    &.active {
      color: #454545;
    }
  }

  &__menu {
    position: relative;
    margin: 0;
    list-style: none;

    ul {
      list-style: none;
    }
  }
}
.header-nav__mobile {
  background-color: #ffffff;
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.weather-forecast {
  &__box {
    position: absolute;
    width: 706px;
    height: 128px;
    left: 0;
    top: 100%;

    background: #4D92FA;
    border-radius: 8px;
    align-items: center;
    z-index: 99;
    padding-left: 15px;

    display: none;
  }

  &__label {
    position: relative;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    color: #6DA2E8;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media screen and (min-width: 992px) {
      &:hover {
        .weather-forecast__box {
          display: flex;
        }
      }
    }
  }

  &__city {
    font-style: normal;
    font-weight: bold;
    font-size: 26.5951px;
    line-height: 38px;
    text-align: center;
    color: #FFFFFF;
  }
  &__heat {
    font-style: normal;
    font-weight: bold;
    font-size: 76.5939px;
    line-height: 58px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: flex-end;
  }
  &__detail {
    font-style: normal;
    font-weight: 500;
    font-size: 14.246px;
    line-height: 21px;
    color: #FFFFFF;
    text-align: left;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
  }

  &__others {
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    width: 336px;
  }

  &__day {
    font-style: normal;
    font-weight: 500;
    font-size: 14.5013px;
    line-height: 21px;
    color: #FFFFFF;

    display: flex;
    flex-direction: column;
  }
}

.header-nav {
  height: 66px;
  display: none;
  background-color: #ffffff;

  @media screen and (min-width: 992px) {
    display: flex;
    align-items: center;
  }

  &__select {
    background-color: transparent;
    border: 0;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;
    margin-left: 3px;

    width: 50px;

    @media screen and (min-width: 992px) {
      width: auto;
    }
  }

  &__submenu {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 99;

   &-box {
    margin-top: 19px;
    padding: 20px;
    background: #FCFCFC;
    border: 1px solid #D3D3D3;
    box-sizing: border-box;
    border-radius: 4px;
   }
  }

  &__submenu-heading {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #EB0015;
    border-bottom: 1px solid #E9E9E9;
    margin-bottom: 10px;
    padding-bottom: 8px;
  }

  &__submenu-link {
    color: #000000;
  }

  &__item {
    margin-right: 30px;
    list-style: none;

    &:first-child {
      margin-left: 5px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      .header-nav__submenu {
        display: block;
      }
    }

    @media screen and (max-width: 1400px) {
      margin-right: 26px;
    }
    @media screen and (max-width: 1200px) {
      margin-right: 11px;
    }
    @media screen and (max-width: 1030px) {
      margin-right: 6px;
    }
  }

  &__link {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #000000;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}

.header-submenu {
  background-color: #EB0015;
  overflow: auto;
  display: flex;
  flex-wrap: nowrap;

  &__link {
    height: 51px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 30px;

    display: flex;
    align-items: center;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }
}

.header-slogan {
  background-image: url(../../../images/son-dakika.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #EB0015;
  // Yas background: #353535;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* or 100% */
  max-width: 100%;
  overflow: auto;

  /* Neutral/White */

  color: #FFFFFF;
  height: 50px;

  @media screen and (min-width: 992px) {
    background-image: none;
    // Yas background: #353535;
  }

  &__inner {
    height: 100%;
  }

  &__label {
    width: 190px;
    min-width: 190px;
    margin-right: 40px;
    font-weight: 600;
    font-size: 22px;
    /* identical to box height, or 218% */

    letter-spacing: -0.4px;

    display: none;

    font-size: 18px;
    background: #C2111A;
    // Yas background: #000000;
    position: relative;
    padding-left: 10px;
    transform: skewX(-26deg);
    height: 50px;

    &--transform {
      transform: skewX(26deg);
      position: relative;
      display: flex;
      height: 100%;
      align-items: center;

      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      letter-spacing: -0.4px;
      color: #FFFFFF;

      &:before {
        content: ' ';
        width: 7px;
        position: absolute;
        top: 0;
        left: calc(100% + 3px);
        height: 50px;
        transform: skewX(-26deg);
        background: #C2111A;
        // Yas background: #000000;
      }

      &:after {
        content: ' ';
        width: 3px;
        position: absolute;
        top: 0;
        left: calc(100% + 13px);
        height: 50px;
        transform: skewX(-26deg);
        background: #C2111A;
        // Yas background: #000000;
      }
    }

    &:before {
      content: ' ';
      width: 400%;
      position: absolute;
      top: 0;
      right: 100%;
      height: 50px;
      background: #C2111A;
      // Yas background: #000000;
    }

    @media screen and (min-width: 992px) {
      display: block;
    }
  }

  &__content {
    width: 100%;
    overflow: hidden;

    @media screen and (min-width: 992px) {
      width: calc(100% - 190px - 40px);
    }
  }

  &__news{
    float:left;
    width:33.3%;
    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }

  &__item-description {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14.5px;
    line-height: 16px;
    font-weight: bolder;
  }

  &__item-time {
    border: 2px solid rgba(255, 255, 255, 0.4);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px 8px;
    font-weight: bold;
    font-size: 19px;
    line-height: 17px;
    height: 32px;
  }

  &__item {
    color: #ffffff;
    text-decoration: none;
    line-height: 14px;
    padding-right: 10px;
  }

  &__status {
    margin-bottom: 5px;

    i {
      font-size: 8px;
    }
  }
}

.header-advertisement {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #128E6E;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* or 100% */
  max-width: 100%;
  overflow: auto;
  /* Neutral/White */
  color: #FFFFFF;
  height: 85px;

  @media screen and (min-width: 992px) {
    background-image: none;
  }

  &__inner {
    height: 100%;
  }

  &__label {
    width: 190px;
    min-width: 190px;
    margin: auto;
    font-weight: 600;
    font-size: 22px;
    /* identical to box height, or 218% */
    letter-spacing: -0.4px;
    display: none;
    font-size: 18px;
    background: #128E6E;
    position: relative;
    padding-left: 10px;
    transform: skewX(-26deg);
    height: 50px;

    &--transform {
      transform: skewX(26deg);
      position: relative;
      display: flex;
      height: 100%;
      align-items: center;

      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      letter-spacing: -0.4px;
      color: #FFFFFF;

      &:before {
        content: ' ';
        width: 7px;
        position: absolute;
        top: 0;
        left: calc(100% + 3px);
        height: 50px;
        transform: skewX(-26deg);
        background: #128E6E;
      }

      &:after {
        content: ' ';
        width: 3px;
        position: absolute;
        top: 0;
        left: calc(100% + 13px);
        height: 50px;
        transform: skewX(-26deg);
        background: #128E6E;
      }
    }

    &:before {
      content: ' ';
      width: 400%;
      position: absolute;
      top: 0;
      right: 100%;
      height: 50px;
      background: #128E6E;
    }

    @media screen and (min-width: 992px) {
      display: block;
    }
  }

  &__content {
    width: 100%;
    overflow: hidden;

    @media screen and (min-width: 992px) {
      width: calc(100% - 190px - 40px);
    }
  }

  &__item-description {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14.5px;
    line-height: 16px;
    font-weight: bolder;
    background-color: transparent;
    border: 0;
    color: white;

    &--primary {
      font-size: 20px;
      opacity: 1;
      color: gray;
    }
  }

  &__logo {
    height: 30px;
  }

  &__option {
    color: black;
    font-size: 15px;
  }

  &__select-wrapper {
    position: relative;
    display: inline;

    &::after {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'icomoon' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      content: "\e902";

      position: absolute;
      right: 12px;
      top: 50%;
      margin-top: -8px;
    }
  }

  &__select {
    background-color: transparent;
    border: 0;
    margin-left: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;

    width: 80px;

    @media screen and (min-width: 992px) {
      width: auto;
    }
  }

  &__item-time {
    border: 2px solid rgba(255, 255, 255, 0.4);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px 8px;
    font-weight: bold;
    font-size: 19px;
    line-height: 17px;
    height: 32px;
  }

  &__time {
    background-color: #97c9bb;
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  &__time-separator {
    color: #FFFFFF;
    font-size: 20px;
    margin-left: 3px;
    margin-right: 3px;
  }

  &__item {
    color: #ffffff;
    text-decoration: none;
    line-height: 14px;
    padding-right: 10px;
  }

  &__status {
    margin-bottom: 5px;

    i {
      font-size: 8px;
    }
  }
}

.header-search {
  background: #EB0015;
  width: 100%;
  max-height: 0;
  transition: 600ms;
  overflow: hidden;

  &.active {
    max-height: 200px;
  }

  &__form {
    width: 860px;
    max-width: calc(100% - 40px);
    margin: 20px auto;
    border-radius: 3px;

    display: flex;

    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
  }

  &__input {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    border-radius: 3px 0px 0px 3px;
    border: 0;



    color: #7B7B7B;
    height: 48px;
    width: calc(100% - 48px);

    padding-left: 20px;
  }

  &__button {
    min-width: 48px;
    width: 48px;
    height: 48px;
    background: #161616;
    border-radius: 0px 3px 3px 0px;
    border: 0;
    color: #fff;
    font-size: 20px;
  }
}

.mm-navbar {
  text-align: left;
  justify-content: flex-start;
}

.mm-menu_offcanvas {
  width: 100% !important;
  max-width: unset !important;
}

.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout {
  transform: translate3d(100vw,0,0) !important;
}

.mm-btn_close:after,
.mm-btn_close:before {
  display: none;
}

.mm-navbar {
  --mm-color-background: #ffffff;
  --mm-color-text-dimmed: #fff;
  --mm-color-button: #fff;
  height: 60px;
  justify-content: space-between;
  padding-right: 20px;
}
.mm-panel {
  --mm-color-background: #ffffff;
  --mm-color-text: #000;
  --mm-color-button: #2A2A2A;
}

.header-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-text-block {
  background-color: white;
  padding: 10px;
  opacity: 0.4;
}

$orange: #ff6600;
$yellow:#DDB574;
$black: #000000;
$white: #ffffff;
$alabaster: #f8f8f8;
$dustyGray: #9A9A9A;
$doveGray: #707070;
$stroke: #cecdcd;
$gray: #919191;
$emperor: #525252;
$silver: #c6c6c6;
$red: #f24747;
$redLess: #fff2f2;
$pagination-active-bg :  #EB0015;
$pagination-active-border-color: #EB0015;
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');
@import 'bootstrap';
@import 'form';
@import 'pagination';
@import 'swiper';
@import 'mmenu';
@import 'fancybox';
@import 'tippy.js';
@import 'filter';
@import 'social-sharing';

@mixin icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.filter-select {
  position: relative;

  &__bar {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 200% */
    text-align: center;
    color: #5B5B5B;
    background-color: transparent;
    border: 0;
    padding-right: 20px;

    &--primary {
      background: #FFFFFF;
      border: 1px solid #EDEDED;
      box-sizing: border-box;
      border-radius: 4px;
      width: 100%;
      display: block;
      height: 40px;
      text-align: left;
      padding-left: 10px;
    }
  }

  &:after {
    @include icomoon();
    content: "\e902";
    font-size: 10px;
    color: #5B5B5B;

    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -4px;
  }

  &--primary {
    &:after {
      right: 15px;
    }
  }
}

.filter-separator {
  height: 22px;
  width: 1px;
  background-color: #E7E7E7;
  margin: 0 14px;
}

// Required
@import "~bootstrap/scss/functions";
@import "./variables";
@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/flex";
@import "~bootstrap/scss/utilities/spacing";
@import "~bootstrap/scss/utilities/sizing";
@import "~bootstrap/scss/utilities/text";

@media (min-width: 1024px) {
	.container {
		min-width: 1024px;
	}
}

@media (min-width: 1400px) {
	.container {
		min-width: 1200px;
	}
}

@media (max-width: 1023px) {
	.container {
		max-width: 100%;
	}
}

.container {
  .container {
		min-width: 100%;
    width: auto;
    padding: 0;
	}
}


@media screen and (min-width: 992px) {
  .column-left {
    flex: 0 0 calc(100% - 320px);
    max-width: calc(100% - 320px);
  }

  .column-right {
    flex: 0 0 33.3333333333%;
    max-width: 320px;
  }

  .column-pr-left {
    flex: 0 0 calc(100% - 380px);
    max-width: calc(100% - 380px);
  }

  .column-pr-right {
    flex: 0 0 33.3333333333%;
    max-width: 380px;
  }
}

.clamp {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &-2 {
    -webkit-line-clamp: 2;
  }

  &-3 {
    -webkit-line-clamp: 3;
  }

  &-4 {
    -webkit-line-clamp: 4;
  }

  @media screen and (min-width: 992px) {
    &-lg-1 {
      -webkit-line-clamp: 1;
    }
    &-lg-2 {
      -webkit-line-clamp: 2;
    }
    &-lg-3 {
      -webkit-line-clamp: 3;
    }
    &-lg-4 {
      -webkit-line-clamp: 4;
    }
  }
}


@import '../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css';

.fancybox-hidden-content {
	display: none;
}

.fancybox-content {
	padding: 35px 20px 25px;
	border-radius: 5px;

  @media screen and (min-width: 992px) {
    padding: 58px 47px 25px;
  }
}

.fancybox-is-open .fancybox-bg {
	opacity: .6;
}

.fancybox-rich-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: $emperor;
}

.fancybox-slide--html .fancybox-close-small {
	padding: 0;
	width: 30px;
	height: 30px;
	top: 15px;
	right: 15px;

	svg {
		width: 30px;
		height: 30px;
	}
}
.fancybox-slide__merchant {
  padding: 0;
}
.fancybox__merchant-alert {
  padding: 0;
  vertical-align: bottom;
  border-radius: 0;

  .fancybox-close-small {
    top: 0;
    right: 0;
  }
}

.fancybox__agreements {
  background: #fafafa;
  padding: 0;

  @media (min-width: 992px) {
    width: 700px;
    background: $white;
  }
}
.fancybox {
  &__agreement-title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.5px;
    text-align: left;
    color: #000000;
    padding: 18px;
    background-color: #f8f8f8;
  }

  &__agreement-content {
    padding: 20px;
    max-height: 500px;
    overflow: auto;
  }
}
.fancybox-slide--html {
  padding: 6px;
}

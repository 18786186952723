.social-sharing {
  &--writer {
    border-bottom: 1px solid #EDEDED;
    padding-bottom: 20px;
    margin-bottom: 20px;

    @media screen and (min-width: 992px) {
      padding-bottom: 25px;
      margin-bottom: 30px;
    }
  }

  &__left {

  }
  &__right {
  }
  &__link {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    color: #ffffff;
    background: #00B5EF;

    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &--twitter {
      background: #00B5EF;
    }

    &--x {
      background: #11161b;
    }

    &--facebook {
      background: #4364A2;
    }

    &--instagram {
      background: #AD2D86;
    }

    &--telegram {
      background: #24A0EA;
    }

    &--linkedin {
      background: #0669AA;
      font-size: 14px;
    }

    &--envelope {
      background: #FF606E;
    }

    &--like {
      background: #4364A2;
      font-size: 15px;
    }

    &--whatsapp {
      background: #4ba243;
      font-size: 15px;
    }
  }

  &__button {
    padding: 0;
    height: 36px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 137px;

    &--google {
      font-size: 24px;

      width: 100%;

      @media screen and (min-width: 992px) {
        width: auto;
      }
    }
  }
}

.image-wrapper {
  position: relative;
  margin-bottom: 20px;

  &--detail {
    margin-bottom: 10px;
  }

  &__inner {
    display: none;
    position: absolute;
    background-color: rgba(0,0,0, .66);
    color: #ffffff;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    justify-content: center;
    align-items: center;
  }

  &__search {
    font-size: 45px;
  }

  &__search-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

  }

  &__zoom {
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  &:hover {
    .image-wrapper {
      &__inner {
        display: flex;
      }
    }
  }
}

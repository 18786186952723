* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-font-feature-settings: normal;
}

html,
body,
applet,
object,
iframe,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: top;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
  touch-action: manipulation;
}

body {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-style: normal;
  background: #ffffff 0% 0% no-repeat padding-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.blocked {
  position: fixed;
  height: 100%;
  width: 100%;
}
body.dark {
  background: #212121;
}

a {
  text-decoration: none;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  color: #C4C4C4;
  line-height: 24px;
}

ul {
  margin-left: 1.5rem;
}

ol {
  margin-left: 1.5rem;
}

a:focus,
input:focus,
select:focus,
button:focus {
  outline: 0;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

select {
  -webkit-appearance: none;
}

img {
  max-width: 100%;
  height: auto;
}

select option {
  color: #000;
}

.breadcrumbs-control {
  border-bottom: 2px solid #EAEAEA;
  padding-bottom: 2px;
  margin-bottom: 20px;
}
.breadcrumbs-control__label {
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.breadcrumbs-control__label::after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #EB0015;
  left: 0;
  top: calc(100% + 2px);
}
.breadcrumbs-control__label--main {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  color: #605F5F;
}
.breadcrumbs-control__nav-link {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  /* identical to box height, or 175% */
  /* Black/500 (Gray) */
  color: #959393;
}
.breadcrumbs-control__nav-button {
  cursor: pointer;
}
.breadcrumbs-control--dark {
  border-color: #3c3c3c;
}
.breadcrumbs-control--dark .breadcrumbs-control__label {
  color: #ffffff;
}

.text-red {
  color: #EB0015;
}

.preloading {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.preloading img {
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

@keyframes stretch {
  0% {
    -webkit-transform: scale(0.3) rotate(0deg);
    transform: scale(0.3) rotate(0deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
  100% {
    -webkit-transform: scale(1.5) rotate(360deg);
    transform: scale(1.5) rotate(360deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
}
@-webkit-keyframes stretch {
  0% {
    -webkit-transform: scale(0.3) rotate(0deg);
    transform: scale(0.3) rotate(0deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
  100% {
    -webkit-transform: scale(1.5) rotate(360deg);
    transform: scale(1.5) rotate(360deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
}
.header {
  box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.15);
}
.header__top {
  background: #161616;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  /* identical to box height, or 175% */
  text-align: center;
  color: #848484;
  height: 32px;
}
.header__default-link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.header__default-link i {
  font-size: 10px;
}
@media screen and (max-width: 540px) {
  .header__default-link {
    font-size: 12px;
  }
  .header__default-link i {
    display: none;
  }
}
.header__red-link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  text-align: center;
  color: #FF0000;
  display: flex;
  align-items: center;
  white-space: nowrap; /* the text does not fall on the bottom line on narrow screens */
}
.header__red-link i {
  font-size: 10px;
}
@media screen and (max-width: 540px) {
  .header__red-link {
    font-size: 12px;
    padding-left: 10px;
  }
  .header__red-link i {
    display: none;
  }
}
@media screen and (min-width: 540px) {
  .header__red-link {
    padding-left: 20px;
  }
}
.header__blue-link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  text-align: center;
  color: #0552C2;
  display: flex;
  align-items: center;
  white-space: nowrap; /* the text does not fall on the bottom line on narrow screens */
}
.header__blue-link i {
  font-size: 10px;
}
@media screen and (max-width: 540px) {
  .header__blue-link {
    font-size: 12px;
    padding-left: 10px;
    color: #4D92FA;
  }
  .header__blue-link i {
    display: none;
  }
}
@media screen and (min-width: 540px) {
  .header__blue-link {
    padding-left: 20px;
  }
}
.header__link {
  border: 0;
  background-color: transparent;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  text-align: center;
  text-transform: uppercase;
  color: #CCCCCC;
  cursor: pointer;
}
.header__link.active {
  color: #454545;
}
.header__menu {
  position: relative;
  margin: 0;
  list-style: none;
}
.header__menu ul {
  list-style: none;
}

.header-nav__mobile {
  background-color: #ffffff;
}
@media screen and (min-width: 992px) {
  .header-nav__mobile {
    display: none;
  }
}

.weather-forecast__box {
  position: absolute;
  width: 706px;
  height: 128px;
  left: 0;
  top: 100%;
  background: #4D92FA;
  border-radius: 8px;
  align-items: center;
  z-index: 99;
  padding-left: 15px;
  display: none;
}
.weather-forecast__label {
  position: relative;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: #6DA2E8;
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .weather-forecast__label:hover .weather-forecast__box {
    display: flex;
  }
}
.weather-forecast__city {
  font-style: normal;
  font-weight: bold;
  font-size: 26.5951px;
  line-height: 38px;
  text-align: center;
  color: #FFFFFF;
}
.weather-forecast__heat {
  font-style: normal;
  font-weight: bold;
  font-size: 76.5939px;
  line-height: 58px;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  align-items: flex-end;
}
.weather-forecast__detail {
  font-style: normal;
  font-weight: 500;
  font-size: 14.246px;
  line-height: 21px;
  color: #FFFFFF;
  text-align: left;
  padding-left: 20px;
  margin-left: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.weather-forecast__others {
  padding-left: 20px;
  margin-left: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  width: 336px;
}
.weather-forecast__day {
  font-style: normal;
  font-weight: 500;
  font-size: 14.5013px;
  line-height: 21px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
}

.header-nav {
  height: 66px;
  display: none;
  background-color: #ffffff;
}
@media screen and (min-width: 992px) {
  .header-nav {
    display: flex;
    align-items: center;
  }
}
.header-nav__select {
  background-color: transparent;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #FFFFFF;
  margin-left: 3px;
  width: 50px;
}
@media screen and (min-width: 992px) {
  .header-nav__select {
    width: auto;
  }
}
.header-nav__submenu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 99;
}
.header-nav__submenu-box {
  margin-top: 19px;
  padding: 20px;
  background: #FCFCFC;
  border: 1px solid #D3D3D3;
  box-sizing: border-box;
  border-radius: 4px;
}
.header-nav__submenu-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #EB0015;
  border-bottom: 1px solid #E9E9E9;
  margin-bottom: 10px;
  padding-bottom: 8px;
}
.header-nav__submenu-link {
  color: #000000;
}
.header-nav__item {
  margin-right: 30px;
  list-style: none;
}
.header-nav__item:first-child {
  margin-left: 5px;
}
.header-nav__item:last-child {
  margin-right: 0;
}
.header-nav__item:hover .header-nav__submenu {
  display: block;
}
@media screen and (max-width: 1400px) {
  .header-nav__item {
    margin-right: 26px;
  }
}
@media screen and (max-width: 1200px) {
  .header-nav__item {
    margin-right: 11px;
  }
}
@media screen and (max-width: 1030px) {
  .header-nav__item {
    margin-right: 6px;
  }
}
.header-nav__link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.header-submenu {
  background-color: #EB0015;
  overflow: auto;
  display: flex;
  flex-wrap: nowrap;
}
.header-submenu__link {
  height: 51px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
  margin-right: 30px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.header-submenu__link:last-child {
  margin-right: 0;
}

.header-slogan {
  background-image: url(../../../images/son-dakika.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #EB0015;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* or 100% */
  max-width: 100%;
  overflow: auto;
  /* Neutral/White */
  color: #FFFFFF;
  height: 50px;
}
@media screen and (min-width: 992px) {
  .header-slogan {
    background-image: none;
  }
}
.header-slogan__inner {
  height: 100%;
}
.header-slogan__label {
  width: 190px;
  min-width: 190px;
  margin-right: 40px;
  font-weight: 600;
  font-size: 22px;
  /* identical to box height, or 218% */
  letter-spacing: -0.4px;
  display: none;
  font-size: 18px;
  background: #C2111A;
  position: relative;
  padding-left: 10px;
  transform: skewX(-26deg);
  height: 50px;
}
.header-slogan__label--transform {
  transform: skewX(26deg);
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.4px;
  color: #FFFFFF;
}
.header-slogan__label--transform:before {
  content: " ";
  width: 7px;
  position: absolute;
  top: 0;
  left: calc(100% + 3px);
  height: 50px;
  transform: skewX(-26deg);
  background: #C2111A;
}
.header-slogan__label--transform:after {
  content: " ";
  width: 3px;
  position: absolute;
  top: 0;
  left: calc(100% + 13px);
  height: 50px;
  transform: skewX(-26deg);
  background: #C2111A;
}
.header-slogan__label:before {
  content: " ";
  width: 400%;
  position: absolute;
  top: 0;
  right: 100%;
  height: 50px;
  background: #C2111A;
}
@media screen and (min-width: 992px) {
  .header-slogan__label {
    display: block;
  }
}
.header-slogan__content {
  width: 100%;
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  .header-slogan__content {
    width: calc(100% - 190px - 40px);
  }
}
.header-slogan__news {
  float: left;
  width: 33.3%;
}
@media screen and (max-width: 992px) {
  .header-slogan__news {
    width: 100%;
  }
}
.header-slogan__item-description {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14.5px;
  line-height: 16px;
  font-weight: bolder;
}
.header-slogan__item-time {
  border: 2px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 8px;
  font-weight: bold;
  font-size: 19px;
  line-height: 17px;
  height: 32px;
}
.header-slogan__item {
  color: #ffffff;
  text-decoration: none;
  line-height: 14px;
  padding-right: 10px;
}
.header-slogan__status {
  margin-bottom: 5px;
}
.header-slogan__status i {
  font-size: 8px;
}

.header-advertisement {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #128E6E;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* or 100% */
  max-width: 100%;
  overflow: auto;
  /* Neutral/White */
  color: #FFFFFF;
  height: 85px;
}
@media screen and (min-width: 992px) {
  .header-advertisement {
    background-image: none;
  }
}
.header-advertisement__inner {
  height: 100%;
}
.header-advertisement__label {
  width: 190px;
  min-width: 190px;
  margin: auto;
  font-weight: 600;
  font-size: 22px;
  /* identical to box height, or 218% */
  letter-spacing: -0.4px;
  display: none;
  font-size: 18px;
  background: #128E6E;
  position: relative;
  padding-left: 10px;
  transform: skewX(-26deg);
  height: 50px;
}
.header-advertisement__label--transform {
  transform: skewX(26deg);
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.4px;
  color: #FFFFFF;
}
.header-advertisement__label--transform:before {
  content: " ";
  width: 7px;
  position: absolute;
  top: 0;
  left: calc(100% + 3px);
  height: 50px;
  transform: skewX(-26deg);
  background: #128E6E;
}
.header-advertisement__label--transform:after {
  content: " ";
  width: 3px;
  position: absolute;
  top: 0;
  left: calc(100% + 13px);
  height: 50px;
  transform: skewX(-26deg);
  background: #128E6E;
}
.header-advertisement__label:before {
  content: " ";
  width: 400%;
  position: absolute;
  top: 0;
  right: 100%;
  height: 50px;
  background: #128E6E;
}
@media screen and (min-width: 992px) {
  .header-advertisement__label {
    display: block;
  }
}
.header-advertisement__content {
  width: 100%;
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  .header-advertisement__content {
    width: calc(100% - 190px - 40px);
  }
}
.header-advertisement__item-description {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14.5px;
  line-height: 16px;
  font-weight: bolder;
  background-color: transparent;
  border: 0;
  color: white;
}
.header-advertisement__item-description--primary {
  font-size: 20px;
  opacity: 1;
  color: gray;
}
.header-advertisement__logo {
  height: 30px;
}
.header-advertisement__option {
  color: black;
  font-size: 15px;
}
.header-advertisement__select-wrapper {
  position: relative;
  display: inline;
}
.header-advertisement__select-wrapper::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e902";
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -8px;
}
.header-advertisement__select {
  background-color: transparent;
  border: 0;
  margin-left: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #FFFFFF;
  width: 80px;
}
@media screen and (min-width: 992px) {
  .header-advertisement__select {
    width: auto;
  }
}
.header-advertisement__item-time {
  border: 2px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 8px;
  font-weight: bold;
  font-size: 19px;
  line-height: 17px;
  height: 32px;
}
.header-advertisement__time {
  background-color: #97c9bb;
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.header-advertisement__time-separator {
  color: #FFFFFF;
  font-size: 20px;
  margin-left: 3px;
  margin-right: 3px;
}
.header-advertisement__item {
  color: #ffffff;
  text-decoration: none;
  line-height: 14px;
  padding-right: 10px;
}
.header-advertisement__status {
  margin-bottom: 5px;
}
.header-advertisement__status i {
  font-size: 8px;
}

.header-search {
  background: #EB0015;
  width: 100%;
  max-height: 0;
  transition: 600ms;
  overflow: hidden;
}
.header-search.active {
  max-height: 200px;
}
.header-search__form {
  width: 860px;
  max-width: calc(100% - 40px);
  margin: 20px auto;
  border-radius: 3px;
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}
.header-search__input {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  border-radius: 3px 0px 0px 3px;
  border: 0;
  color: #7B7B7B;
  height: 48px;
  width: calc(100% - 48px);
  padding-left: 20px;
}
.header-search__button {
  min-width: 48px;
  width: 48px;
  height: 48px;
  background: #161616;
  border-radius: 0px 3px 3px 0px;
  border: 0;
  color: #fff;
  font-size: 20px;
}

.mm-navbar {
  text-align: left;
  justify-content: flex-start;
}

.mm-menu_offcanvas {
  width: 100% !important;
  max-width: unset !important;
}

.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
  transform: translate3d(100vw, 0, 0) !important;
}

.mm-btn_close:after,
.mm-btn_close:before {
  display: none;
}

.mm-navbar {
  --mm-color-background: #ffffff;
  --mm-color-text-dimmed: #fff;
  --mm-color-button: #fff;
  height: 60px;
  justify-content: space-between;
  padding-right: 20px;
}

.mm-panel {
  --mm-color-background: #ffffff;
  --mm-color-text: #000;
  --mm-color-button: #2A2A2A;
}

.header-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-text-block {
  background-color: white;
  padding: 10px;
  opacity: 0.4;
}
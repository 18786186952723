* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-font-feature-settings: normal;
}

html,
body,
applet,
object,
iframe,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: top;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
	touch-action: manipulation;
}

body {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-style: normal;
  background: #ffffff 0% 0% no-repeat padding-box;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.blocked {
	position: fixed;
	height: 100%;
	width: 100%;
  }

  &.dark {
    background: #212121;
  }
}

a {
	text-decoration: none;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  color: #C4C4C4;
  line-height: 24px;

}

ul {
  margin-left: 1.5rem;
}

ol{
  margin-left: 1.5rem;
}

a,
input,
select,
button {
  &:focus {
    outline: 0;
  }
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

select {
    -webkit-appearance: none;
}

img {
	max-width: 100%;
  height: auto;
}

select option {
  color: #000;
}

.tippy-box[data-theme~=orange] {
  color: $white;
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15), 0 4px 80px -8px rgba(36, 40, 47, 0.25), 0 4px 4px -2px rgba(91, 94, 105, 0.15);
  background-color: $orange;

  &[data-placement^=top] > .tippy-arrow:before {
    border-top-color: $orange;
  }

  &[data-placement^=bottom] > .tippy-arrow:before {
    border-bottom-color: $orange;
  }

  &[data-placement^=left] > .tippy-arrow:before {
    border-left-color: $orange;
  }

  &[data-placement^=right] > .tippy-arrow:before {
    border-right-color: $orange;
  }

  > {
    .tippy-backdrop {
      background-color: $orange;
    }

    .tippy-svg-arrow {
      fill: $orange;
    }
  }
}

.tippy-box[data-theme~=merchant] {
  color: $black;
  //box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15), 0 4px 80px -8px rgba(36, 40, 47, 0.25), 0 4px 4px -2px rgba(91, 94, 105, 0.15);
  //background-color: $orange;
  box-shadow: 0 -3px 17px 0 rgba(0,0,0,.1);
  background-color: #ffffff;
  .tippy-arrow {
    transform-style: preserve-3d;

    &::after {
      content: '';
      position: absolute;
      left: -8px;
      transform: translateZ(-1px);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }
  }
  &[data-placement^=top] > .tippy-arrow:before {
    border-top-color: $white;
  }

  &[data-placement^=bottom] {
    & > .tippy-arrow:before {
      border-bottom-color: $white;
    }
  }

  &[data-placement^=left] > .tippy-arrow:before {
    border-left-color: $white;
  }

  &[data-placement^=right] > .tippy-arrow:before {
    border-right-color: $white;
  }

  > {
    .tippy-backdrop {
      background-color: $white;
    }

    .tippy-svg-arrow {
      fill: $white;
    }
  }
}


/*tooltip */
.merchant {
  width: 400px;
  &__message {
    display: flex;
    align-items: center;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1;
    padding: 10px 0;

    &-text {
      font-size: 13px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: #707070;

      @media screen and (max-width: 768px) {
        > div {
          max-width: 80%;
        }
      }
    }
  }

  &__image {
    margin-left: 12px;
    max-width: 60px;
  }

  &__information-row {
    display: flex;

    .merchant__information-label {
      width: 105px;
      font-size: 12px;
      font-weight: 200;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #707070;
      margin-left: 12px;
      min-width: 105px;
    }

    .merchant__information-value {
      font-size: 12px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #707070;
    }
  }

  &__information {
    padding: 10px 0;
    display: flex;
    text-align: left;
    flex-direction: column;
  }

  &__badges {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  &__badge {
    display: flex;
    font-size: 12px;
    align-items: center;

    &:nth-child(2n) {
      margin-left: 10px;
    }

    img {
      height: 17px;
      margin-right: 3px;
    }
  }
}

.merchant-hr-v {
  width: 1px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-right: 6px;
  margin-left: 6px;
}


.tippy-box[data-theme~=orange-py] {
  color: $white;
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15), 0 4px 80px -8px rgba(36, 40, 47, 0.25), 0 4px 4px -2px rgba(91, 94, 105, 0.15);
  background-color: $orange;
  padding: 5px !important;
  text-align: center;
  a {
    color: $white;
  }

  &[data-placement^=top] > .tippy-arrow:before {
    border-top-color: $orange;
  }

  &[data-placement^=bottom] > .tippy-arrow:before {
    border-bottom-color: $orange;
  }

  &[data-placement^=left] > .tippy-arrow:before {
    border-left-color: $orange;
  }

  &[data-placement^=right] > .tippy-arrow:before {
    border-right-color: $orange;
  }

  > {
    .tippy-backdrop {
      background-color: $orange;
    }

    .tippy-svg-arrow {
      fill: $orange;
    }
  }
}
